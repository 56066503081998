<template>
  <section>
    <div
      class="headbanner"
      :style="`background-image:url(${bannerUrl});`"
    >
      <v-container class="container-custom">
        <v-row>
          <v-col> <h1 class="titlepage">Contact Us</h1></v-col>
        </v-row>
      </v-container>
    </div>
    <v-container class="container-custom">
      <v-row>
        <v-col md="6">
          <div class="boxinside">
            <h3>Send Enquiry</h3>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              class="form-section"
            >
              <v-text-field
                v-model="fullname"
                outlined
                label="Full Name"
                class="mr-btm-minus"
                :rules="fullnameRule"
              ></v-text-field>
              <v-text-field
                v-model="subject"
                outlined
                label="Subject"
                class="mr-btm-minus"
                :rules="subjectRule"
              ></v-text-field>
              <v-text-field
                v-model="phone"
                outlined
                label="Phone"
                class="mr-btm-minus"
                :rules="phoneRule"
              ></v-text-field>
              <v-text-field
                v-model="email"
                outlined
                label="Email"
                class="mr-btm-minus"
                :rules="emailRules"
              ></v-text-field>
              <v-textarea
                v-model="description"
                outlined
                label="Description"
                class="mr-btm-minus"
                rows="4"
                :rules="descriptionRule"
              ></v-textarea>

              <v-btn
                v-if="!allloadingTraining"
                :disabled="!valid"
                class="submitbutton btn-dark"
                @click="validate"
              >
                Send
              </v-btn>
              <v-btn
                v-if="allloadingTraining"
                class="submitbutton btn-dark"
                disabled
              >
                <v-progress-circular indeterminate></v-progress-circular>
              </v-btn>

              <v-snackbar
                v-if="allSendEnquiry.message != undefined"
                v-model="snackbar"
              >
                <span> {{ allSendEnquiry.message }} </span>
              </v-snackbar>
            </v-form>
          </div>
        </v-col>
        <v-col md="6">
          <div class="boxinside">
            <h2>Let's Connect</h2>
            <p>We'd love to here from you. Here's how you can reach us.</p>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3532.858401525193!2d85.33564151557104!3d27.69077098279867!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb1974c77c4745%3A0x503cc981d19634c9!2sNECO%20JOBS%20PVT.%20LTD!5e0!3m2!1sen!2snp!4v1617046143152!5m2!1sen!2snp"
              width="100%"
              height="250"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
            ></iframe>
            <ul class="navigation">
              <li>Address : New Baneshwor Kathmandu, Nepal</li>
              <li>Phone : <a href="tel:+977 9801855636">+977 9801855636</a></li>
              <li>
                Email :
                <a href="mailto:info@necojobs.com.np">info@necojobs.com.np</a>
              </li>
            </ul>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Contact",
  data: () => ({
    valid: true,
    snackbar: false,
    fullname: "",
    subject: "",
    phone: "",
    email: "",
    description: "",
    fullnameRule: [(v) => !!v || "Fullname is required"],
    subjectRule: [(v) => !!v || "Subject is required"],
    phoneRule: [(v) => !!v || "Phone is required"],
    emailRules: [(v) => !!v || "Email is required"],
    descriptionRule: [(v) => !!v || "Description is required"],
    bannerUrl: require("@/assets/placeholder.jpg"),
  }),
  methods: {
    ...mapActions(["contactUs", "getAssets","getSingleMetaPage"]),
    validate() {
      if (this.$refs.form.validate()) {
        let sendEnquiry = {
          fullname: this.fullname,
          subject: this.subject,
          email: this.email,
          phone: this.phone,
          description: this.description,
        };

        this.contactUs(sendEnquiry);
      }
    },
  },
  updated() {
    if (this.allSendEnquiry.message != undefined) {
      this.snackbar = true;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    }
  },
  computed: {
    ...mapGetters(["allloadingTraining", "allSendEnquiry", "allAssets","allMeta"]),
  },
  metaInfo() {
    return {
      title: this.allMeta && this.allMeta.title,
      meta: [
        {
          name: "description",
          content: this.allMeta && this.allMeta.description,
        },
        {
          name: "keywords",
          content: this.allMeta && this.allMeta.keywords,
        },
      ],
    };
  },
  async created() {
    await this.getAssets({
      title: "ContactUs",
      connectedTo: "page",
    });
    this.bannerUrl = this.allAssets[0].image;

    await this.getSingleMetaPage("contact");
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.boxinside {
  box-shadow: 0 1px 1px 0 rgb(66 66 66 / 8%), 0 1px 3px 1px rgb(66 66 66 / 16%);
  transition: width 235ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
  padding: 20px;
  background: $white;
  margin-bottom: 20px;
  padding: 20px;
  background: $white;
  margin-bottom: 20px;
  margin-top: 20px;
  h3 {
    font-size: $iconfont;
  }
  h2 {
    font-size: 35px;
    font-weight: bold;
    color: $colororange;
  }
  p {
    font-size: 18px;
  }
}
.nomargin {
  li {
    margin-right: 0;
    margin-left: 15px;
  }
}
.btn-dark {
  background: $tagcolor !important;
  color: $white;
  width: 100%;
  min-height: 50px;
}
.navigation {
  display: flex;
  flex-flow: column;
  margin-left: 0;
  margin-top: 20px;
  li {
    margin-left: 0;
    font-size: 18px;
    line-height: 25px;
  }
  a {
    color: $dark;
  }
  
}

.headbanner {
  background-size: cover;
  height: 450px;
  width: 100%;
  background-position: center center;
}
@media screen and (max-width: 992px) {
  .headbanner {
    background-size: cover;
    height: 120px;
    width: 100%;
  }}
</style>
